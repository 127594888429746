<template>
    <div>
        <CDataTable
            :items="promecodes"
            :fields="promocodeFields"
            :items-per-page="10"
            :loading="loading"
            class="modalTable"
            hover
            pagination
        >
        <template #validFlag="{item}">
              <td>
                <CIcon
                  v-if="item.validFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #approvedFlag="{item}">
              <td>
                <CIcon
                  v-if="item.approvedFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #cancelledFlag="{item}">
              <td>
                <CIcon
                  v-if="item.cancelledFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template> 
            <template #createTime="{item}">
              <td>
                {{ dateFormat(item.createTime) }}
              </td>
            </template> 
            <template #validDateStart="{item}">
              <td>
                {{ dateFormat(item.validDateStart) }}
              </td>
            </template> 
            <template #validDateEnd="{item}">
              <td>
                {{ dateFormat(item.validDateEnd) }}
              </td>
            </template> 
            <template #promocode="{item}">
              <td>
                {{ item.promocode.id }}
              </td>
            </template> 
            <template #useCount="{item}">
              <td>
                {{ item.useCount + ' / ' + item.maxCount }}
              </td>
            </template> 
        </CDataTable> 
    </div>
</template>

<script>
    import moment from 'moment';
    export default{
        name: "CustomerPromecodes",
        props:{
            params: Object,
        },
        computed:{
            promecodes: function() {
                return this.$store.getters.customerPromecodes
            },
            loading: function() {
                return this.$store.getters.socialLoading
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                promocodeFields: [
                    { key: 'id', label: 'ID', _style: 'font-size:12px' },
                    { key: 'createTime', label: 'Tarih', _style: 'font-size:12px' },
                    { key: 'promocode', label: 'Kod ID', _style: 'font-size:12px' },
                    { key: 'code', label: 'Kod', _style: 'font-size:12px' },
                    { key: 'validDateStart', label: 'Başlangıç Tarihi', _style: 'font-size:12px' },
                    { key: 'validDateEnd', label: 'Bitiş Tarihi', _style: 'font-size:12px' },
                    { key: 'useCount', label: 'Kullanım Durumu', _style: 'font-size:12px' },
                    { key: 'totalUsedCount', label: 'Toplam Kullanım', _style: 'font-size:12px' },
                    { key: 'validFlag', label: 'Geçerlilik', _style: 'font-size:12px' },
                    { key: 'approvedFlag', label: 'Onay', _style: 'font-size:12px' },
                    { key: 'cancelledFlag', label: 'İptal', _style: 'font-size:12px' }
                ],
            }
        },
        methods: {
            dateFormat(date){
                return moment(date).format("DD.MM.YYYY")
            },
        }
    }
</script>